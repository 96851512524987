import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AeroDesk from "../../images/landing/aero.jpg";
import Car from "../../images/landing/car1800.jpg";
import CarTab from "../../images/landing/car1800.jpg";
import CarDesk from "../../images/landing/car1800.jpg";
import propertyTab02 from "../../images/landing/1802.jpg";
import DownTownDesk from "../../images/landing/1803.png";
import Location01 from "../../images/landing/place01.png";
import Location02 from "../../images/landing/place02.png";
import Location03 from "../../images/landing/place03.png";
import Location04 from "../../images/landing/place04.png";
import Location05 from "../../images/landing/place05.jpg";
import { useWindowSize } from "../../hooks/window-size";
import TeamCard from "./TeamListingCard";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./TeamListing.scss";
import { graphql, useStaticQuery } from "gatsby";
const TeamListing = () => {

  const data = useStaticQuery(graphql`
    query GetTeamsData {
      glstrapi {
        teamCategories(sort: "sort_order:ASC") {
          URL
          Name
          id
        }

        teams {
          select_categories {
            Name
            id
            URL
          }
          Name
          Email
          Designation
          URL
          Image {
            url
          }
          Tile_GIF {
            url
          }
          GIF {
            url
          }
          Meta_Title
          Meta_Description
          Phone
          URL
          imagetransforms
          id
          sort_order
        }
      }
    }  
  `)

  const [windowWidth] = useWindowSize();

  const allTeams = data.glstrapi.teams;
  allTeams.sort((a, b) => a.sort_order - b.sort_order);

  const [teams, setTeams] = useState(allTeams);
  const teamCategories = data.glstrapi.teamCategories;

  const teamsPerPage = 24;
  const [teamsToShow, setTeamsToShow] = useState([]);
  const [next, setNext] = useState(teamsPerPage);

  useEffect(() => {
    teams && updateTeamsToShow(0, teamsPerPage);
  }, [teams]);
  
  const updateTeamsToShow = (start, end) => {
    //console.log("Log Teams",teams);
    //console.log("Log Filter",filter);
    if(filter!="all") {
      const filteredTeams = allTeams.filter(team => team.select_categories.find(item => item.Name === filter));
      //setTeamsToShow(filteredTeams.slice(start, end));
      const slicedTeams = filteredTeams.slice(start, end);
      setTeamsToShow([...teamsToShow, ...slicedTeams]);
    } else {
      const slicedTeams = teams.slice(start, end);
      setTeamsToShow([...teamsToShow, ...slicedTeams]);
    }
  };

  const handleLoadMore = () => {
    updateTeamsToShow(next, next + teamsPerPage);
    setNext(next + teamsPerPage);
  };



  const [filter, setFilter] = useState("all");
  const [filterClose, setFilterclose] = useState(false);
  const filterBtn = () => {
    setFilterclose(!filterClose);
  };

  useEffect(() => {
    filterTeams(filter);
  }, [filter]);

  const filterTeams = (category) => {
    setNext(teamsPerPage)
    if (category === "all") {
      setTeamsToShow(allTeams.slice(0, teamsPerPage));
      return 
    }
    
    const filteredTeams = allTeams.filter(team => team.select_categories.find(item => item.Name === category));
    // const filteredTeams = allTeams.filter((team) => team.select_categories[0].Name === category);
    setTeamsToShow(filteredTeams.slice(0, teamsPerPage));
  }

  //console.log("dsadasd",teamsToShow.length, next);
  
  return (
    <div className="team-listing-wrapper">
      <section className="category-wrap">
        <Container>
          <Row className="RowMerge">
            <Col className="RowMerge">
              <button
                type="button"
                className="category-btn dropdown-toggle btn btn-primary"
                onClick={filterBtn}
              >
                {filter == "all" ? "all categories" : filter}

                <i className="drop-arrow"></i>
              </button>
              <nav className={`nav-wrap ${filterClose ? "nav-menu-open" : ""}`}>
                <ul className="nav-menu">
                  <li>
                    <button
                      type="button"
                      className={
                        filter === "all" ? "filter-link active" : "filter-link"
                      }
                      active={filter === "all"}
                      onClick={() => {
                        setFilter("all")
                        setFilterclose(false)
                      }}
                    >
                      everyone
                    </button>
                  </li>

                  {teamCategories.map(category => {

                    const filteredTeams = allTeams.filter(
                      team => team.select_categories.find(item => item.Name === category.Name)
                    );
                     if(filteredTeams.length <= 0) {
                       return null
                     }

                    return (
                    <li>
                      <button
                        type="button"
                        className={
                          filter === category.Name
                            ? "filter-link active"
                            : "filter-link"
                        }
                        active={filter === category.Name}
                        onClick={() => {
                          setFilter(category.Name)
                          setFilterclose(false)
                        }}
                      >
                        {category.Name}
                      </button>
                    </li>
                  )})}
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="team-listing-section container">
          <div className="team-list-container">
            {teamsToShow.map((team, index) => {
              let processedImages = JSON.stringify({})
              if (team?.imagetransforms?.Image_Transforms) {
                processedImages = team.imagetransforms.Image_Transforms
              }
              return (
                <div className="team-card" key={team.Name + index}>
                  <TeamCard
                  item={team}
                  processedImages={processedImages}
                  showGif
                />
                </div>
              )
            })}
          </div>
          {teamsToShow  && teamsToShow.length >= next &&
                <div className="btn-wrap">
                  <a onClick={handleLoadMore} className=" btn btn-secondary" >Load More</a>
                </div>
            }
      </section>
      
    </div>
  )
};
export default TeamListing;