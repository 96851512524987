import React, { useEffect, useState } from "react"
import GetGGFXImage from "../common/site/get-ggfx-image"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"
import "./TeamCard.scss"
import { AltSiteName } from "../common/site/utils"
import { getitems } from "../utils/utils"
import Stars from "../stars"

const TeamCard = ({ item, processedImages, showGatsbyImage, showGif }) => {

  const data = useStaticQuery(graphql`
    {
      glstrapi {
        teams {
          Name
        }
      }
    }
  `)

  const AllPeople = data?.glstrapi?.teams;

  const [testimonials, setTestimonials] = useState([]);
  const [overallReviews, setOverallReviews] = useState([]);

  let stringDes = item.Designation;
  let Designation;
  if (typeof stringDes === 'string') {
    Designation = stringDes.trim();
  }

  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC.replace('-dev', '')}/stb-google-reviews/google-review-reviews`
    getitems({url: url, setOverallReviews: setOverallReviews, setTestimonials: setTestimonials, teamName: item.Name, AllPeople: AllPeople});
  }, [])

  var array =[]
    {testimonials.map((review, i) => {
      let strTitle = review.comment;
      let itemName = item.Name
      let Name;
      let firstName = typeof itemName === 'string' ? itemName.split(' ').slice(0, -1)[0] : ""
        if (typeof strTitle === 'string') {
          Name = strTitle.includes(firstName)
        }
        if (Name == true) {
          array.push(review.starRating)
        }
      })
    }

  const totalArray = []
  array && array.length > 0 && array.map((data, i) => {
    let count = 0;
    if(data === "ONE") {
      count=1
    }
    if(data === "TWO") {
      count=2
    }
    if(data === "THREE") {
      count=3
    }
    if(data === "FOUR") {
      count=4
    }
    if(data === "FIVE") {
      count=5
    }
    totalArray.push(count)
  })

  // sum of array value
  const sumval = (arr) => { 
    let sum = 0;
    for (let i = 0; i < arr.length; i++) 
        sum += arr[i]; 
    return sum; 
  } 

  let values = sumval(totalArray)

  let avgStar = parseInt(Math.round(values)) / testimonials?.length

  let Img = <></>

  if (showGif) {
    Img = <img src={item.Tile_GIF.url} alt={item.Name + " - " + item.Designation + AltSiteName} />
  } else if (showGatsbyImage) {
    Img = (
      <GetGatsbyImage
        image={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
      />
    )
  } else {
    Img = (
      <GetGGFXImage
        imagename={"teams.Image.sliderimg"}
        imagesources={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
        imagetransformresult={processedImages}
        id={item.id}
      />
    )
  }

  return (
    <div className="team-wrap ">
      <div className="team-img zoom">
        {/* {!ShowGatsbyImage ? <GetGGFXImage
          imagename={"teams.Image.sliderimg"}
          imagesources={item.Image}
          fallbackalt={item.Name + " - " + item.Designation}
          imagetransformresult={processedImages}
          id={item.id}
        /> : <GetGatsbyImage
        image={item.Image}
        fallbackalt={item.Name + " - " + item.Designation}
        />} */}
        <Link className="zoom" to={`/meet-the-team/${item.URL}/`}>{Img}</Link>

        {/* <img src={item.Image.url} alt="" /> */}
      </div>
      <div className="team-content">
        <Link to={`/meet-the-team/${item.URL}/`}>
          <h2>
            {item.Name}
            <i className="icon-right"></i>
          </h2>
        </Link>

        <h3>{item.Designation}</h3>
        {Designation == 'Managing Director' || Designation ==  'Managing Partner' ||  Designation == 'Luxury Property Consultant' || Designation == 'Senior Property Consultant'|| Designation == 'Property Consultant' || Designation == 'Lettings Consultant' || Designation == 'Associate Director' ?
          <div className="count-section">
            <Stars count={avgStar} reviewLength={testimonials.length}/>
            <p>{testimonials.length > 1 ? `(${testimonials.length} Reviews)` : `(${testimonials.length == 0 ? 'No' : "1"} Review)`} </p>
          </div>
        :""}
        {/* <div className="contact-info">
          <i className="icon-call"></i>
          <a href={`tel:${item.Phone}`} className="tel">
            {item.Phone}
          </a>
        </div>
        <div className="contact-info">
          <i className="icon-whatsapp"></i>
          <a href={`https://wa.me/+971585313748?text=Hi ${item.Name.split(" ")[0]},`} target="_blank" className="tel">
            Click to WhatsApp
          </a>
        </div> */}
      </div>
    </div>
  )
}

export default TeamCard